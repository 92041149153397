.home__content {
    display: grid;
    grid-template-columns:116px repeat(2, 1fr);
    align-items: center;
    padding-top: 5.5rem;
    column-gap: 2rem;
    margin-bottom: 5rem;
}
.button-home{
    display: block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
    width: fit-content;
    margin: 0 auto;
}
.button-home:hover{
    background-color: var(--title-color-dark);

}
.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1.2rem;
    z-index: 1;
}


.home__social-icon{
    font-size: 1.25rem;
    color: var(--title-color-dark);
}
.home__social-icon:hover{
   transform: scale(1.1);
}

.home__title {
    font-size: var(--big-font-size);
    margin-bottom: var(--mb-0-25);
    text-align: center;
    font-family: title-font !important;

}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);

}

.home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home__description {
    padding: 0 4rem 0 0;
    margin-bottom: var(--mb-2-5);
    margin-left: 3.6rem;
    justify-self: center;

}

.home__img {
    background: url(../../assets/angel-ayodoro-foto-de-perfil.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    order: 1;
    justify-self: center;
    width: 350px;
    height: 350px;
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
}


/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .home__content {
        grid-template-columns: 200px repeat(2, 1fr);
        column-gap: 1.25rem;
    }

    .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: .8rem;
    }

    .home__description {
        max-width: initial;
        margin-top: 1rem;
        margin-bottom: var(--mb-2-5);
        justify-content: center;
        padding: -.3rem;
        text-align: center;

    }

    .home__img {
        width: 250px;
        height: 250px;
    }

    .home__scroll-button {
        margin-left: 25rem;

    }

    .button-home {
        justify-self: center;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .home__content {
        grid-template-columns:116px repeat(2, 1fr);
        padding-top: 3.5rem;
        padding-bottom: 4rem;
    }

    .home__img {
        order: initial;
        justify-self: center;

    }

    .home__data {
        grid-column: 2;


    }

    .home__title {
        font-family: title-font;
        text-align: center;
    }

    .home__scroll-button {
        display: none;
    }

    .home__description {
        max-width: initial;
        padding: 0 rem;
        text-align: center;
        margin-bottom: 2rem;
    }
    .button-home {
        justify-self: center;
    }
}

/* For medium devices */
@media screen and (max-width: 576px) {
    .home__content {
        grid-template-columns: 0px 1fr;
        padding-top: 2.5rem;
        margin-left: -1rem;
    }

    .home__description {
        max-width: initial;

    }

    .home__title {
        padding-top: 3rem;
        font-size: var(--h1-font-size);
    }
    .home__social-icon{
        font-size: .5rem;
    }
}


/* For small devices */
@media screen and (max-width: 350px) {
    .home__content {
        grid-template-columns: 1fr;
    }

    .home__description {
        text-align: center;
        grid-template-columns: 1fr;
        gap: 0.5rem;
        margin-bottom: var(--mb-2);
    }

    .home_img {
        width: 180px;
        height: 180px;

    }


}