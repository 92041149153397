::-webkit-input-placeholder {
    color: transparent;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: transparent;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: transparent;
}

:-ms-input-placeholder {
    color: transparent;
}


.contact__container {
    grid-template-columns: repeat(2, max-content);
    justify-content: center;
    column-gap: 6rem;
    padding-bottom: 3rem;
}

.contact__title {
    text-align: center;
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-1-5);
}

.contact__info {
    display: grid;
    row-gap: 1rem;
    grid-template-columns: 300px;

}

.contact__card,
.contact__card-mail {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
    border-radius: 0.75rem;
    text-align: center;

}

.contact__card-icon {
    font-size: 2rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-25);
}

.contact__card-title,
.contact__card-data {
    font-size: var(--small-font-size);

}

.contact__card-title {
    font-weight: var(--font-medium);
}

.contact__card-data {
    display: block;
    margin-bottom: var(--mb-0-75);
}

.contact__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: inline;
    align-items: center;
    justify-content: center;
    column-gap: 0.25rem;
}

.contact__button:hover .contact__button-icon {
    transform: translate(0.25rem);
}

.contact__form {
    width: 360px;
}

.contact__form-div {
    position: relative;
    margin-bottom: var(--mb-2);
    height: 4rem;
}

.contact__form-input {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 2px solid rgba(0, 0, 0, 0.3);
    background: none;
    color: var(--text-color);
    border-radius: 0.6rem;
    padding: 1rem;
}

.contact__form-tag {
    position: absolute;
    top: 0;
    left: 1.25rem;
    font-size: var(--small-font-size);
    transform: translateY(1.4rem);
    transition: transform 0.5s, color ease 0.3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; 
}

.contact__form-input:focus+.contact__form-tag,
.contact__form-input:not(:placeholder-shown)+.contact__form-tag {
    transform: translateY(-0.4rem) scale(.9);
    background-color: var(--container-color);
    color: var(--text-color);
    padding: 0 0.5rem;
    border-radius: 0.5rem;
}

.contact__form-area {
    height: 11rem;
}


.button {
    display: block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
    width: fit-content;
    margin: 0 auto;
    outline: 0;
    transition: outline 0.3s ease-in-out;
  
}
@media screen and (max-width:992px) {
    .contact__container {
        column-gap: 3rem;

    }
}

@media screen and (max-width:768px) {
    .contact__container {
        grid-template-columns: 1fr;
        row-gap: 3rem;

    }

    .contact__info {
        justify-content: center;

    }

    .contact__form {
        margin: 0 auto;
    }


}

@media screen and (max-width:576px) {
    .work__container {
        grid-template-columns: 1fr;
    }

    .work__img {
        width: 100%;

    }
}

/*Small devices*/
@media screen and(max-width:350px) {

    .work__item {
        font-size: var(--small-font-size);

    }

    .work__filters {
        column-gap: 0.25rem;
    }

}