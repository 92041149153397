.footer{
    background-color: var(--title-color-dark);
    border-top: 1px solid rgba(0,0,0,0.1);

}
.footer__container {
    padding: 2rem 0 6rem;

}
.footer__title,.footer__link{
    color: var(--container-color);
}
.footer__title{
    text-align: center;
    margin-bottom: var(--mb-2);
}
.footer__list{
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}
.footer__social{
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}
.footer__social-link{
    color: var(--container-color);
    font-size: 1.125rem;
    padding: 0.4rem;
    display: inline-flex;
}
.footer__copy{
    display: block;
    margin-top: 4.5rem;
    color: var(--container-color);
    text-align: center;
    font-size: var(--smaller-font-size);

}