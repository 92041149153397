
.work__modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;


}
.work__modal-content{
    display: block;
    width: 800px;
    position: relative;
    background-color: var(--container-color);
    padding: 4.5rem 2.5rem 2.5rem;
    border-radius: 1.5rem
   
}

.modal-image{
    width: 800px;
    height: 540;
    border-radius: 1rem;
}


.work__modal-close{
    position:absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    color:  var(--title-color-dark);
    cursor: pointer;
}
.work__modal-close:hover{
   transform: scale(0.7);
   transform: rotate(45deg);
}
.work__modal-title, .work__modal-description{
    text-align: center;
}
.work__modal-title{
    font-size: var(--h2-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-2);

}
.work__modal-description{
    font-size: var(--normal-font-size);
    padding: 0 3.5rem;
    margin-top: 2.5rem;
    margin-bottom: var(--mb-2);
}
.work__modal-work{
    row-gap: 0.75rem;
}
.work__modal-service{
    display: flex;
    align-items: center;
    column-gap: .5rem;
}
/* Active Modal */
.active-modal{
    opacity: 1;
    visibility: visible;

}
