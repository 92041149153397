.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}
.work__item{
    color:var(-title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius: 0.5rem;
    text-transform: capitalize;
}
.work__item:hover{
    background-color: var(--title-color);
    color: var(--container-color);

}

.work__container{
    grid-template-columns: repeat(2,max-content);
    gap:3rem;
    justify-content: center;
}
.work__card{
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1.25rem;
    border-radius: 1rem;
}
.work__img {
    width: 23rem;
    border-radius: 1rem;
    margin-bottom:  1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;

}
.work__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-top:0;
    justify-content: center;
    text-align: center;
    color: var(--title-color-dark);
    padding: 3% 0;
    border-radius: 1rem 1rem 0 0;
}

.work__button {
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
}
.work__button-icon{
    font-size: 1rem !important;
    transition: 0.4s!important;
}
.work__button:hover .work__button-icon{
    transform: scale(1.1);
}
.show-more-button{
    color: var(--text-color);
    font-size: var(--small-font-size);
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.25rem;
}
/*Active work*/
.active-work{
    background-color: var(--title-color);
    color: var(--container-color);
}


@media screen and (max-width:768px){
    .work__container{
        grid-template-columns: max-content;
    }

}
@media screen and (max-width:576px){
    .work__container{
        grid-template-columns: 1fr;
    }
    .work__img{
        width: 100%;

    }
   

}
/*Small devices*/
@media screen and(max-width:350px){

    .work__item{
        font-size: var(--small-font-size);
        
    }
    .work__filters{
        column-gap: 0.25rem;
    }

}